<script setup>
  import {
    onMounted,
  } from 'vue'

  import { useI18n } from '@shared/i18n.js'
  const { vt } = useI18n('welcome.index.js')

  const {
    neiborhoods
  } = window.app.page

  let resizeTimer = null

  onMounted(() => {
    slider()
    $(window).on('resize', () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => slider(), 100)
    })
  })

  const slider = () => {
    const windowWidth = $(window).width();
    const $slider = $('.slider-neighborhoods');
    if (windowWidth <= 1200) {
      if (!$slider.hasClass('slick-initialized')) {
        $slider.slick({
          arrows: true,
          infinite: false,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 4,
          cssEase: 'ease-out',
          swipeToSlide: true,
          touchThreshold: 10,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4.2,
                slidesToScroll: 3,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3.2,
                slidesToScroll: 3,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2.2,
                slidesToScroll: 2,
                arrows: false,
              }
            },
          ]
        })
      }
    }
    else {
      if ($slider.hasClass('slick-initialized')) {
        $slider.slick('unslick')
      }
    }
  }
</script>
<template>
  <section id="home-neighborhoods" class="home-neighborhoods">
    <div class="section_wrap">
      <h2 class="c-ttl">{{ vt('explore_top_neighborhoods') }}</h2>
      <div class="slider slider-neighborhoods neighborhoods-list">
        <template v-for="prefecture in neiborhoods" :key="`neighborhood-${prefecture.code}`">
          <div class="item">
            <a :href="prefecture.url">
              <div class="photo">
                <img :src="`/vendor/main/img/${prefecture.image}`">
              </div>
              <div class="text">
                <p class="place">{{ prefecture.label }}</p>
              </div>
            </a>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
